import React from 'react'

import mainIcon from '../../assets/images/icons/icon-lg-iso27001.png'
import icon1 from '../../assets/images/icons/icon-template-box.png'
import icon2 from '../../assets/images/icons/icon-risk-assess.png'
import icon3 from '../../assets/images/icons/icon-scorecards.png'
import icon4 from '../../assets/images/icons/icon-audit.png'
import process from '../../assets/images/services/process-line.png'
import iconcheck from '../../assets/images/services/icon-check-process.png'



const iso27001 = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="row">
                            <div className="d-flex col-lg-4 justify-content-center align-items-start">
                                <img src={mainIcon} alt="ISO27000 Certifications" style={{maxWidth: '300px'}} />
                            </div>
                            <div className="col-lg-8">
                                <h1> The ISO27001 module helps get you ready for ISO27001 certification</h1>
                                <p className="lead">ISO27001 Information Security Management System (ISMS)</p>
                                <p>ISO27001 is a globally accepted and often required by companies to demonstrate you’re compliance with industry accepted security controls.  The ISMS establishes and maintains your information security and compliance program.</p>
                            </div>
                        </div>
                    
                    
                    <hr/>
                       
                        <h4 className="text-center ptb-40 ">Tools and Templates built on Atlassian</h4>
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-solutions-box">
                                <div className="icon">
                                    <img src={icon1} style={{width: 120}} alt="Document Templates" />
                                    </div> 
                                    <p>Document Templates</p> 
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6">
                                <div className="single-solutions-box">
                                <div className="icon">
                                    <img src={icon2} style={{width: 120}} alt="Risk Assessment" />
                                </div>
                                    <p>Risk Assessment</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6 offset-lg-0 ">
                                <div className="single-solutions-box">
                                    <div className="icon">
                                        <img src={icon3} style={{width: 120}} alt="Control Scorecards" />
                                    </div>
                                    <p>Control Scorecards</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 offset-lg-0 ">
                                <div className="single-solutions-box">
                                    <div className="icon">
                                        <img src={icon4}  style={{width: 120}}alt="Audit Program" />
                                    </div>
                                    <p>Audit Program</p>
                                </div>
                            </div>
                        </div>
                        <p className="text-center col-lg-6 offset-lg-3">Agility is pre-configured with all the tools and templates you need to implement your ISO27001 program.</p>
                        <p className="text-center col-lg-6 offset-lg-3">Easily track program status, control compliance, document status and remediation all within your or Ekko hosted Atlassian Cloud instance</p>
                        <div className="process-rule">
                            <img src={process} className="mtb-70" alt="process" />
                        </div>
                        <h1 className="mtb-70 text-center" >The ISO27001 Readiness &amp; Implementation Process</h1>
                        <div className="row">
                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> Start your ISMS
                                    </h3>
                                    <p className="step-subtitle">
                                        Agility provides you everything you need to start your ISO27001 Information Security Management System (ISMS). 
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            Scope guidance and templates 
                                        </li>
                                        <li>
                                            Defined, roadmap project plan and milestones 
                                        </li>
                                        <li>
                                            Templates for Clauses 4-10, everything you need to start your ISMS 
                                        </li>
                                        <li>
                                            Control and remediation dashboards and reports 
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> Create Information Security Policies &amp; Procedures 
                                    </h3>
                                    <p className="step-subtitle">
                                        Pre-built ISO27001 compliant policy and procedure templates  
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            All policies required under Annex A 
                                        </li>
                                        <li>
                                            ISMS Governance policies 
                                        </li>
                                        <li>
                                            Control procedure templates 
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> Gap Analysis and Scorecard 
                                    </h3>
                                    <p className="step-subtitle">
                                        Establish your control baseline and program starting point  
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            Generate control scorecard and reports  
                                        </li>
                                        <li>
                                            Define your remediation and implementation roadmap 
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> Perform Risk Assessment 
                                    </h3>
                                    <p className="step-subtitle">
                                        Conduct your information security risk assessment  
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            Define and evaluate your risks 
                                        </li>
                                        <li>
                                          Document risk treatment plans  
                                        </li>

                                        <li>
                                          Generate your Statement of Applicability  
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> Select Certification Firm 
                                    </h3>
                                    <p className="step-subtitle">
                                        ISO27001 requires an external registrar to conduct your certification audit   
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            Stage 1 - Audit readiness review 
                                        </li>
                                        <li>
                                          Stage 2 - Certification audit of your ISMS and program 
                                        </li>
                                        <li>
                                          Get certified and maintain your program 
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> Program Implementation
                                    </h3>
                                    <p className="step-subtitle">
                                       Agility provides you all the tools to implement, track and remediate your Information Security program 
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            Remediation and control improvement task tracking 
                                        </li>
                                        <li>
                                            Reporting and dashboards  
                                        </li>
                                        <li>
                                            Reminders and scheduling
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> Internal Audit
                                    </h3>
                                    <p className="step-subtitle">
                                       Conduct your Internal Audit readiness assessment inside Agility  
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            ISO27001 readiness assessment 
                                        </li>
                                        <li>
                                            Pre-built audit test cases  
                                        </li>
                                        <li>
                                            Generate and manage control evidence 
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> Certification
                                    </h3>
                                    <p className="step-subtitle">
                                       Conduct your ISO27001 certification audit with your selected registrar audit firm  
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            Get certified
                                        </li>
                                        <li>
                                            Conduct annual surveillance audits  
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> ISMS Maintenance
                                    </h3>
                                    <p className="step-subtitle">
                                       Agility helps you maintain your ISMS   
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                           Pre-built task templates 
                                        </li>
                                        <li>
                                            Schedule and track ongoing activities  
                                        </li>
                                        <li>
                                            ISMS Meeting reminders  
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* <div className="step-panel">
                            <h3 className="step-title">
                                XXX
                            </h3>
                            <p className="step-subtitle">
                                XXX
                            </p>
                            <ul className="step-sublist">
                                <li>
                                    XXX
                                </li>
                                <li>
                                    XXX
                                </li>
                                <li>
                                    XXX
                                </li>
                                <li>
                                    XXX
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default iso27001